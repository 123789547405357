import React from 'react'
import { graphql } from 'gatsby'
import HeaderSEO from '../../components/partials/HeaderSEO'
import get from 'lodash/get'

import Layout from '../../components/Layout'

class policy extends React.Component {
    render() {
        const pageSEO = get(this, 'props.data.allContentfulPages.edges[0].node')
        return (
            <Layout>
                <HeaderSEO pageSEO={pageSEO} />
                {/* <!-- ========== PRIVACY POLICY SECTION ========== --> */}
                <section className='mt-5 pt-5 pb-5 px-2'>
                    <div className='container-1'>
                        <div className='row'>
                            <div className='col-md-12'>
                                <h1 className='underline h3-like'>PRIVACY POLICY</h1>
                                <p>
                                    This policy (together with our{' '}
                                    <a href='/legals/terms-and-conditions/'>Terms of Use</a>
                                    &nbsp;and any other documents referred to on it) sets out the
                                    basis on which any personal data we collect from you, or that
                                    you provide to us, will be processed by us.&nbsp; Please read
                                    the following carefully to understand our views and practices
                                    regarding your personal data and how we will treat it. By
                                    visiting our website www.lenses.io (our site), and using the
                                    Community Edition of our product, you are accepting and
                                    consenting to the practices described in this policy.
                                </p>
                                <h4>INFORMATION WE MAY COLLECT FROM YOU</h4>
                                <p>We may collect and process the following data about you:</p>
                                <p className='pl-3'>
                                    <strong>Information you give us</strong>. You may give us
                                    information about you by filling in forms on our site or by
                                    corresponding with us by phone, e-mail or otherwise. The
                                    information you give us may typically include your name,
                                    address, organisation, e-mail address and phone number.{' '}
                                </p>
                                <p className='pl-3'>
                                    <strong>Information we collect about you</strong>. With regard
                                    to each of your visits to our site we may automatically collect
                                    the following information:
                                </p>
                                <p className='pl-5'>
                                    technical information, including the Internet protocol (IP)
                                    address used to connect your computer to the Internet, your
                                    login information, browser type and version, time zone setting,
                                    browser plug-in types and versions, operating system and
                                    platform;
                                </p>
                                <p className='pl-5'>
                                    information about your visit, including the full Uniform
                                    Resource Locators (URL) clickstream to, through and from our
                                    site (including date and time); products you viewed or searched
                                    for; page response times, download errors, length of visits to
                                    certain pages, page interaction information (such as scrolling,
                                    clicks, and mouse-overs), and methods used to browse away from
                                    the page and any phone number used to call our customer service
                                    number.{' '}
                                </p>
                                <p>
                                    <strong>Information we receive from other sources</strong>. We
                                    may receive information about you if you use any other services
                                    we provide. We are also working closely with third parties
                                    (including, for example, business partners, sub-contractors in
                                    technical services, analytics providers and search information
                                    providers) and may receive information about you from them.
                                </p>
                                <h4>COOKIES</h4>
                                <p>
                                    Our website uses cookies to distinguish you from other users of
                                    our website. This helps us to provide you with a good experience
                                    when you browse our website and also allows us to improve our
                                    site. For detailed information on the cookies we use and the
                                    purposes for which we use them see our{' '}
                                    <a href='/legals/cookies/' className=''>
                                        Cookie Policy
                                    </a>
                                    .
                                </p>
                                <h4>USES MADE OF THE INFORMATION</h4>
                                <p>We use information held about you in the following ways:</p>
                                <p className='pl-3'>
                                    <strong>Information you give to us.</strong> We may use this
                                    information:
                                </p>
                                <p className='pl-5'>
                                    - to carry out our obligations arising from any contracts
                                    entered into between you and us and to provide you with the
                                    information, products and services that you request from us;
                                </p>
                                <p className='pl-5'>
                                    - to provide you with information about other goods and services
                                    we offer that are similar to those that you have already
                                    purchased or enquired about;
                                </p>
                                <p className='pl-5'>
                                    - to provide you, or permit selected third parties to provide
                                    you, with information about goods or services we feel may
                                    interest you. If you are an existing customer, we will only
                                    contact you by electronic means (e-mail or SMS) with information
                                    about goods and services similar to those which were the subject
                                    of a previous sale or negotiations of a sale to you.
                                </p>
                                <p className='pl-5'>
                                    - to notify you about changes to our products and services;
                                </p>
                                <p className='pl-5'>
                                    - to ensure that content from our site is presented in the most
                                    effective manner for you and for your computer.
                                </p>
                                <p className='pl-5'>
                                    - to improve the experience and value of our Community Edition
                                    product.
                                </p>
                                <p className='pl-3'>
                                    <strong>Information we collect about you</strong>. We may use
                                    this information:
                                </p>
                                <p className='pl-5'>
                                    - to administer our site and for internal operations, including
                                    troubleshooting, data analysis, testing, research, statistical
                                    and survey purposes;
                                </p>
                                <p className='pl-5'>
                                    - to improve our site to ensure that content is presented in the
                                    most effective manner for you and for your computer;{' '}
                                </p>
                                <p className='pl-5'>
                                    - as part of our efforts to keep our site safe and secure;
                                </p>
                                <p className='pl-5'>
                                    - to measure or understand the effectiveness of advertising we
                                    serve to you and others, and to deliver relevant advertising to
                                    you;
                                </p>
                                <p className='pl-5'>
                                    - to make suggestions and recommendations to you and other users
                                    of our site about goods or services that may interest you or
                                    them.
                                </p>
                                <p className='pl-3'>
                                    <strong>Information we receive from other sources</strong>. We
                                    may combine this information with information you give to us and
                                    information we collect about you. We may use this information
                                    and the combined information for the purposes set out above
                                    (depending on the types of information we receive).
                                </p>
                                <h4>DISCLOSURE OF YOUR INFORMATION</h4>
                                <p>
                                    We may share your personal information with any member of our
                                    group, which means our subsidiaries, our ultimate holding
                                    company and its subsidiaries, as defined in section 1159 of the
                                    UK Companies Act 2006.
                                </p>
                                <p>
                                    We may share your information with selected third parties
                                    including:
                                </p>
                                <p>
                                    Business partners, suppliers and sub-contractors for the
                                    performance of any contract we enter into with them or you.
                                </p>
                                <p>
                                    Analytics and search engine providers that assist us in the
                                    improvement and optimisation of our site.
                                </p>
                                <p>We may disclose your personal information to third parties:</p>
                                <p className='pl-3'>
                                    - In the event that we sell or buy any business or assets, in
                                    which case we may disclose your personal data to the prospective
                                    seller or buyer of such business or assets.
                                </p>
                                <p className='pl-3'>
                                    - If Lenses.io Ltd or substantially all of its assets are
                                    acquired by a third party, in which case personal data held by
                                    it about its customers will be one of the transferred assets.
                                </p>
                                <p className='pl-3'>
                                    - If we are under a duty to disclose or share your personal data
                                    in order to comply with any legal obligation, or in order to
                                    enforce or apply our terms of use{' '}
                                    <a href='/legals/terms-and-conditions/'>Terms of Use </a>
                                    and other agreements; or to protect the rights, property, or
                                    safety of Lenses.io Limited, our customers, or others. This
                                    includes exchanging information with other companies and
                                    organisations for the purposes of fraud protection and credit
                                    risk reduction.
                                </p>
                                <h4>WHERE WE STORE YOUR DATA</h4>
                                <p>
                                    The data that we collect from you may be transferred to, and
                                    stored at, a destination outside the European Economic Area
                                    (&ldquo;EEA&rdquo;). It may also be processed by staff operating
                                    outside the EEA who work for us, our subsidiaries, affiliates,
                                    or for one of our suppliers. &nbsp;By submitting your personal
                                    data, you agree to this transfer, storing or processing. We will
                                    take all steps reasonably necessary to ensure that your data is
                                    treated securely and in accordance with this privacy policy. All
                                    information you provide to us is stored on our secure servers.{' '}
                                </p>
                                <p>
                                    Unfortunately, the transmission of information via the internet
                                    is not completely secure. Although we will do our best to
                                    protect your personal data, we cannot guarantee the security of
                                    your data transmitted to our site; any transmission is at your
                                    own risk. Once we have received your information, we will use
                                    strict procedures and security features to try to prevent
                                    unauthorised access.
                                </p>
                                <h4>YOUR RIGHTS</h4>
                                <p>
                                    You have the right to ask us not to process your personal data
                                    for marketing purposes. We will usually inform you (before
                                    collecting your data) if we intend to use your data for such
                                    purposes or if we intend to disclose your information to any
                                    third party for such purposes. You can exercise your right to
                                    prevent such processing by contacting us at{' '}
                                    <span style={{ fontFamily: 'CeraPro, arial' }}>
                                        <b>
                                            info
                                            <span style={{ width: '1px', display: 'inline-block' }}>
                                                {' '}
                                            </span>
                                            @lenses.io
                                        </b>
                                    </span>{' '}
                                </p>
                                <p>
                                    Our site may, from time to time, contain links to and from the
                                    websites of our partner networks and affiliates.&nbsp; If you
                                    follow a link to any of these websites, please note that these
                                    websites have their own privacy policies and that we do not
                                    accept any responsibility or liability for these policies.&nbsp;
                                    Please check these policies before you submit any personal data
                                    to these websites.
                                </p>
                                <h4>ACCESS TO INFORMATION</h4>
                                <p>
                                    The Act gives you the right to access information held about
                                    you. Your right of access can be exercised in accordance with
                                    the Act. Any access request may be subject to a fee of £10 to
                                    meet our costs in providing you with details of the information
                                    we hold about you.
                                </p>
                                <h4>CHANGES TO OUR PRIVACY POLICY</h4>
                                <p>
                                    Any changes we may make to our privacy policy in the future will
                                    be posted on this page and, where appropriate, notified to you
                                    by e-mail. Please check back frequently to see any updates or
                                    changes to our privacy policy.
                                </p>
                                <h4>CONTACT</h4>
                                <p>
                                    Questions, comments and requests regarding this privacy policy
                                    are welcomed and should be addressed to{' '}
                                    <span style={{ fontFamily: 'CeraPro, arial' }}>
                                        <b>
                                            info
                                            <span style={{ width: '1px', display: 'inline-block' }}>
                                                {' '}
                                            </span>
                                            @lenses.io
                                        </b>
                                    </span>{' '}
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
            </Layout>
        )
    }
}
export default policy

export const pageQuery = graphql`
    query Legals_PolicySEO {
        allContentfulPages(filter: { pageReference: { pageIdentifier: { eq: "Legals_Policy" } } }) {
            edges {
                node {
                    title {
                        title
                    }
                    keywords {
                        keywords
                    }
                    description {
                        description
                    }
                    oGimage {
                        fluid(quality: 100) {
                            src
                        }
                    }
                }
            }
        }
    }
`
